"use client";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { Button } from "../../../components/ui/Button";
import { Calendar } from "../../../components/ui/Calender";
import { Checkbox } from "../../../components/ui/Checkbox";
import { Input } from "../../../components/ui/Input";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "../../../components/ui/Popover";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "../../../components/ui/Select";
import { Textarea } from "../../../components/ui/Textarea";
import useHasPermission from "../../../hooks/use-has-permission";
import useService from "../../../hooks/use-service";
import { cn } from "../../../lib/utils";
import { projectDetails } from "../../../store/tasks";

import { assignedTo, priorities, statuses } from "../data/data";
import { Task } from "../data/schema";
import { DataTableColumnHeader } from "./data-table-column-header";
import { ShowImages } from "./dialog-show-images";
import { DialogUploaderDemo } from "./dialog-uploader-demo";
import SelectChangeValue from "./select-change-value";
import { toast } from "sonner";
import moment from "moment";
import { userData } from "../../../store/user";
import { AxiosError } from "axios";
import { dialogState } from "../../../store/dialog";

export const useColumns = (): ColumnDef<Task>[] => {
	const { id } = useParams();

	const [, setState] = useRecoilState(dialogState);
	const data = useRecoilValue(userData);
	const details = useRecoilValue(projectDetails);
	const { hasPermission } = useHasPermission();
	const { editTask } = useService();
	const queryClient = useQueryClient();

	const adminPrivilege = hasPermission("*");
	const statusEditPrivilege = hasPermission("status");
	const developerNotesEditPrivilege = hasPermission("developer_notes");
	const resolvedAtEditPrivilege = hasPermission("resolved_at");

	const priorityEditPrivilege = hasPermission("priority");
	const assignedToEditPrivilege = hasPermission("assigned_to");
	const whereEditPrivilege = hasPermission("where");
	const sectionEditPrivilege = hasPermission("section");
	const screenshotEditPrivilege = hasPermission("screenshot");
	const reportedAtEditPrivilege = hasPermission("reported_at");
	const designErrorEditPrivilege = hasPermission("design_error");
	const dataEntryErrorEditPrivilege = hasPermission("data_entry_error");
	const issueEditPrivilege = hasPermission("issue");

	const mutation = useMutation({
		mutationKey: ["edit-task", id],
		mutationFn: ({ task_id, form }: { task_id: number | string; form: any }) =>
			editTask(task_id, form),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["tasks", id] });
		},
	});

	const applyChange = (id: number | string, val: any) => {
		toast.promise(
			mutation.mutateAsync({
				task_id: id,
				form: val,
			}),
			{
				loading: "Updating Cell",
				error: (e: AxiosError) => {
					if (e.response?.status === 422) {
						const res = e.response.data as any;
						if (Object.hasOwn(res, "message")) {
							if (typeof res.message === "string") {
								return res.message || "";
							}
						}
					}
					console.log(e);
					return "Something Went Wrong";
				},
				success: "Cell updated successfully",
			}
		);
	};

	return [
		{
			accessorKey: "verified_by_pm",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Verified" />
			),
			cell: ({ row }) => {
				const changeApplied = (e: boolean) => {
					const formData = new FormData();
					formData.append("verified_by_pm", (+e).toString());
					applyChange(row.original.id, formData);
				};

				return (
					<div className="flex items-center justify-center w-full flex-1 h-full">
						<Checkbox
							checked={row.original.verified_by_pm}
							onCheckedChange={(value) => changeApplied(!!value)}
							aria-label="verified"
							disabled={!adminPrivilege || mutation.isPending}
						/>
					</div>
				);
			},
			enableSorting: false,
			maxSize: 50,
			enableResizing: false,
		},
		{
			accessorKey: "priority",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Priority" />
			),
			cell: ({ row }) => {
				const priority = priorities.find(
					(priority) => priority.value === row.getValue("priority")
				);

				if (!priority) {
					return null;
				}

				const onValueChange = (e: string) => {
					const formData = new FormData();
					formData.append("priority", e);
					applyChange(row.original.id, formData);
				};

				return (
					<div className="flex items-center justify-center w-full flex-1 h-full">
						<SelectChangeValue
							values={priorities}
							selectedValue={priority}
							onValueChange={onValueChange}
							disabled={!priorityEditPrivilege || mutation.isPending}
						/>
					</div>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
			maxSize: 100,
			enableResizing: false,
		},
		{
			accessorKey: "assigned_to",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Assigned To" />
			),
			cell: ({ row }) => {
				const assigned = assignedTo.find(
					(to) => to.value === row.getValue("assigned_to")
				);

				if (!assigned) {
					return null;
				}

				const onValueChange = (e: string) => {
					const formData = new FormData();
					formData.append("assigned_to", e);
					applyChange(row.original.id, formData);
				};

				return (
					<SelectChangeValue
						selectedValue={assigned}
						values={assignedTo}
						onValueChange={onValueChange}
						disabled={!assignedToEditPrivilege || mutation.isPending}
					/>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
			maxSize: 100,
			enableResizing: false,
		},
		{
			accessorKey: "employee_id",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Employee" />
			),
			cell: ({ row }) => {
				const assigned = (
					details.employees as {
						id: number;
						name: string;
						email: string;
						type: number;
						created_at: string;
						updated_at: string;
					}[]
				).find((employee) => employee.id === row.getValue("employee_id"));

				const onValueChange = (e: string) => {
					const formData = new FormData();
					formData.append("employee_id", e);
					applyChange(row.original.id, formData);
				};

				return (
					<Select
						value={assigned && assigned.id ? assigned.id.toString() : ""}
						onValueChange={onValueChange}
						disabled={!adminPrivilege || mutation.isPending}
					>
						<SelectTrigger>
							<SelectValue placeholder="Select an Employees" />
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								<SelectLabel>Employees</SelectLabel>
								{details.employees?.map(
									(emp) =>
										emp.type !== 1 &&
										((emp.type === 2 && row.original.assigned_to === 1) ||
											(emp.type === 3 && row.original.assigned_to === 2) ||
											row.original.assigned_to === 3 ||
											row.original.assigned_to === 4) && (
											<SelectItem
												value={emp.id.toString()}
												id={emp.id.toString()}
											>
												{emp.name}
											</SelectItem>
										)
								)}
							</SelectGroup>
						</SelectContent>
					</Select>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
			maxSize: 100,
			enableResizing: false,
		},
		{
			accessorKey: "status",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" />
			),
			cell: ({ row }) => {
				const status = statuses.find(
					(status) => status.value === row.getValue("status")
				);

				if (!status) {
					return null;
				}

				const onValueChange = (e: string) => {
					if (e === "3" || +e === 3) {
						setState({
							id: row.original.id,
							open: true,
						});
					} else {
						const formData = new FormData();
						formData.append("status", e);
						applyChange(row.original.id, formData);
					}
				};

				return (
					<>
						<SelectChangeValue
							selectedValue={status}
							values={statuses}
							onValueChange={onValueChange}
							disabled={
								!(
									(statusEditPrivilege &&
										row.original.employee_id === data?.id) ||
									adminPrivilege
								) || mutation.isPending
							}
						/>
					</>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
			minSize: 170,
			enableResizing: false,
		},
		{
			accessorKey: "where",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Where" />
			),
			cell: ({ row }) => {
				if (!details.where_tags) {
					return null;
				}

				const item = details.where_tags.find(
					(status) => status.value === row.getValue("where")
				);

				if (!item) {
					return null;
				}

				const onValueChange = (e: string) => {
					const formData = new FormData();
					formData.append("where", e);
					applyChange(row.original.id, formData);
				};

				return (
					<SelectChangeValue
						selectedValue={item}
						values={details.where_tags}
						onValueChange={onValueChange}
						disabled={!whereEditPrivilege || mutation.isPending}
					/>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
			maxSize: 70,
			enableResizing: false,
		},
		{
			accessorKey: "section",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Section" />
			),
			cell: ({ row }) => {
				const changeApplied = (e: string) => {
					const formData = new FormData();
					formData.append("section", e);
					applyChange(row.original.id, formData);
				};

				return (
					<Input
						defaultValue={row.getValue("section")}
						onBlur={(e) => changeApplied(e.target.value)}
						className="p-0 m-0 !border-none !outline-none !ring-0"
						disabled={!sectionEditPrivilege || mutation.isPending}
					/>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
			minSize: 200,
		},
		{
			accessorKey: "screenshot",
			header: () => "Related Images",
			cell: ({ row }) => {
				const changeApplied = (e: File) => {
					const formData = new FormData();
					formData.append("screenshot", e);
					applyChange(row.original.id, formData);
				};

				return (
					<div className="flex items-center gap-4">
						<DialogUploaderDemo
							changeApplied={changeApplied}
							disabled={!screenshotEditPrivilege || mutation.isPending}
						/>
						{row.original.screenshot && row.original.screenshot.length > 0 && (
							<ShowImages file={row.original.screenshot} />
						)}
					</div>
				);
			},
		},
		{
			accessorKey: "issue",
			header: () => "Issue",
			cell: ({ row }) => {
				const changeApplied = (e: string) => {
					const formData = new FormData();
					formData.append("issue", e);
					applyChange(row.original.id, formData);
				};

				return (
					<Textarea
						defaultValue={row.original.issue}
						onBlur={(e) => changeApplied(e.target.value)}
						className="p-0 m-0 !border-none !outline-none !ring-0 !min-h-10"
						disabled={!issueEditPrivilege || mutation.isPending}
					/>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
			minSize: 500,
		},
		{
			accessorKey: "developer_notes",
			header: () => "Developer Notes",
			cell: ({ row }) => {
				const changeApplied = (e: string) => {
					const formData = new FormData();
					formData.append("developer_notes", e);
					applyChange(row.original.id, formData);
				};

				return (
					<Textarea
						defaultValue={row.original.developer_notes}
						onBlur={(e) => changeApplied(e.target.value)}
						className="p-0 m-0 !border-none !outline-none !ring-0 !min-h-10"
						disabled={
							!(
								(developerNotesEditPrivilege &&
									row.original.employee_id === data?.id) ||
								data?.privileges_task_fields.includes("*")
							) || mutation.isPending
						}
					/>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
			minSize: 500,
		},
		{
			accessorKey: "completed_hours",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Completed Hours" />
			),
			cell: ({ row }) => {
				return row.original.completed_hours;
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "reported_at",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Date of reported issue" />
			),
			cell: ({ row }) => {
				const changeApplied = (e: Date) => {
					const date = new Date(e);
					const formData = new FormData();

					formData.append("reported_at", `${moment(date).format("YYYY-M-DD")}`);
					applyChange(row.original.id, formData);
				};

				return (
					<Popover>
						<PopoverTrigger asChild>
							<Button
								variant={"outline"}
								className={cn(
									"pl-3 text-left font-normal",
									!new Date(row.getValue("reported_at")) &&
										"text-muted-foreground"
								)}
								disabled={!reportedAtEditPrivilege || mutation.isPending}
							>
								{new Date(row.getValue("reported_at")) ? (
									format(new Date(row.getValue("reported_at")), "PPP")
								) : (
									<span>Pick a date</span>
								)}
								<CalendarIcon className="ml-2 h-4 w-4 opacity-50" />
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-auto p-0" align="start">
							<Calendar
								mode="single"
								selected={new Date(row.getValue("reported_at"))}
								onSelect={(e) => {
									changeApplied(e || new Date());
								}}
								className="rounded-md border"
							/>
						</PopoverContent>
					</Popover>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "resolved_at",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Date of completion" />
			),
			cell: ({ row }) => {
				const changeApplied = (e: Date) => {
					const date = new Date(e);
					const formData = new FormData();
					formData.append("resolved_at", `${moment(date).format("YYYY-M-DD")}`);
					applyChange(row.original.id, formData);
				};

				return (
					<Popover>
						<PopoverTrigger asChild>
							<Button
								variant={"outline"}
								className={cn(
									"pl-3 text-left font-normal",
									!new Date(row.getValue("resolved_at")) &&
										"text-muted-foreground"
								)}
								disabled={
									!(
										(resolvedAtEditPrivilege &&
											row.original.employee_id === data?.id) ||
										data?.privileges_task_fields.includes("*")
									) || mutation.isPending
								}
							>
								{row.getValue("resolved_at") ? (
									format(new Date(row.getValue("resolved_at")), "PPP")
								) : (
									<span>Pick a date</span>
								)}
								<CalendarIcon className="ml-2 h-4 w-4 opacity-50" />
							</Button>
						</PopoverTrigger>
						<PopoverContent className="w-auto p-0" align="start">
							<Calendar
								mode="single"
								selected={
									row.getValue("resolved_at")
										? new Date(row.getValue("resolved_at"))
										: undefined
								}
								onSelect={(e) => {
									changeApplied(e || new Date());
								}}
								className="rounded-md border"
							/>
						</PopoverContent>
					</Popover>
				);
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "start_handle_at",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Start Handle At" />
			),
			cell: ({ row }) => {
				return row.original.start_handle_at;
			},
			filterFn: (row, id, value) => {
				return value.includes(row.getValue(id));
			},
		},
		{
			accessorKey: "design_error",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Designer Error" />
			),
			cell: ({ row }) => {
				const changeApplied = (e: boolean) => {
					const formData = new FormData();
					formData.append("design_error", (+e).toString());
					applyChange(row.original.id, formData);
				};

				return (
					<div className="flex items-center justify-center w-full flex-1 h-full">
						<Checkbox
							checked={row.original.design_error}
							onCheckedChange={(value) => changeApplied(!!value)}
							aria-label="Designer Error"
							disabled={!designErrorEditPrivilege || mutation.isPending}
						/>
					</div>
				);
			},
			enableSorting: false,
			enableHiding: false,
		},
		{
			accessorKey: "data_entry_error",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Data Entry Error" />
			),
			cell: ({ row }) => {
				const changeApplied = (e: boolean) => {
					const formData = new FormData();
					formData.append("data_entry_error", (+e).toString());
					applyChange(row.original.id, formData);
				};

				return (
					<div className="flex items-center justify-center w-full flex-1 h-full">
						<Checkbox
							checked={row.original.data_entry_error}
							onCheckedChange={(value) => changeApplied(!!value)}
							aria-label="Data Entry Error"
							disabled={!dataEntryErrorEditPrivilege || mutation.isPending}
						/>
					</div>
				);
			},
			enableSorting: false,
		},
	];
};
