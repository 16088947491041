import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export type TUserType =
	| "Project Manager"
	| "Developer"
	| "Data Entry"
	| "Client";
export type TUserPrivileges =
	| "*"
	| "status"
	| "resolved_at"
	| "developer_notes"
	| "priority"
	| "assigned_to"
	| "status"
	| "where"
	| "section"
	| "screenshot"
	| "reported_at"
	| "design_error"
	| "data_entry_error"
	| "issue";

export type TUserData = {
	id: number;
	name: string;
	email: string;
	type: TUserType;
	created_at: string;
	updated_at: string;
	privileges_task_fields: TUserPrivileges[];
	token: string;
} | null;

export const userData = atom<TUserData | null>({
	key: "userData",
	default: null,
	effects_UNSTABLE: [persistAtom],
});
