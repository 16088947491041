import { z } from "zod";
import { taskSchema } from "./schema";

export const tasks: z.infer<typeof taskSchema>[] = [
	// {
	// 	id: 1,
	// 	verified: false,
	// 	priority: 1,
	// 	assignedTo: 1,
	// 	status: 1,
	// 	where: 3,
	// 	section: "Product category",
	// 	relatedImage: "https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
	// {
	// 	id: 2,
	// 	verified: false,
	// 	priority: 2,
	// 	assignedTo: 3,
	// 	status: 2,
	// 	where: 2,
	// 	section: "Menu",
	// 	relatedImage: "https://static.vecteezy.com/system/resources/thumbnails/024/646/930/small_2x/ai-generated-stray-cat-in-danger-background-animal-background-photo.jpg",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
	// {
	// 	id: 3,
	// 	verified: false,
	// 	priority: 1,
	// 	assignedTo: 2,
	// 	status: 3,
	// 	where: 2,
	// 	section: "Menu",
	// 	relatedImage: "https://t4.ftcdn.net/jpg/00/97/58/97/360_F_97589769_t45CqXyzjz0KXwoBZT9PRaWGHRk5hQqQ.jpg",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
	// {
	// 	id: 4,
	// 	verified: false,
	// 	priority: 0,
	// 	assignedTo: 4,
	// 	status: 4,
	// 	where: 3,
	// 	section: "Menu",
	// 	relatedImage: "https://images.unsplash.com/photo-1531040630173-7cfb894c8eaa?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8fA%3D%3D",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
	// {
	// 	id: 5,
	// 	verified: false,
	// 	priority: 2,
	// 	assignedTo: 3,
	// 	status: 5,
	// 	where: 1,
	// 	section: "Menu",
	// 	relatedImage: "https://cdn.britannica.com/70/234870-050-D4D024BB/Orange-colored-cat-yawns-displaying-teeth.jpg",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
	// {
	// 	id: 6,
	// 	verified: false,
	// 	priority: 1,
	// 	assignedTo: 2,
	// 	status: 5,
	// 	where: 3,
	// 	section: "Menu",
	// 	relatedImage: "https://www.vetstreet.com/wp-content/uploads/2022/09/shutterstock_772334182.jpg",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
	// {
	// 	id: 7,
	// 	verified: false,
	// 	priority: 0,
	// 	assignedTo: 3,
	// 	status: 4,
	// 	where: 2,
	// 	section: "Menu",
	// 	relatedImage: "",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
	// {
	// 	id: 8,
	// 	verified: false,
	// 	priority: 1,
	// 	assignedTo: 1,
	// 	status: 3,
	// 	where: 3,
	// 	section: "Menu",
	// 	relatedImage: "",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
	// {
	// 	id: 9,
	// 	verified: false,
	// 	priority: 2,
	// 	assignedTo: 2,
	// 	status: 1,
	// 	where: 1,
	// 	section: "Menu",
	// 	relatedImage: "",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
	// {
	// 	id: 10,
	// 	verified: false,
	// 	priority: 2,
	// 	assignedTo: 4,
	// 	status: 2,
	// 	where: 3,
	// 	section: "Menu",
	// 	relatedImage: "",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
	// {
	// 	id: 11,
	// 	verified: false,
	// 	priority: 1,
	// 	assignedTo: 3,
	// 	status: 4,
	// 	where: 1,
	// 	section: "Menu",
	// 	relatedImage: "",
	// 	dateOfReportedIssue: new Date(),
	// 	dateOfCompletion: new Date(),
	// 	developerNotes:
	// 		"Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id explicabo distinctio vitae praesentium, laudantium provident saepe magni, eius, culpa dolore hic nam modi ut. Perferendis recusandae dolorem magni laborum consequuntur nesciunt illum explicabo, voluptate incidunt? Consequatur voluptatem asperiores a alias, quasi, neque vero praesentium optio pariatur cum corrupti nostrum. Voluptatem fugiat assumenda adipisci sit corporis omnis quia nisi, obcaecati, quibusdam tenetur nulla dolorum maiores ea explicabo culpa totam a deleniti qui vero voluptate dolor at animi? Laudantium, esse ipsam! Mollitia odio quis culpa? Recusandae a voluptas minus, velit ut esse inventore quisquam quasi libero accusantium necessitatibus rerum corporis quo quaerat!",
	// 	dataEntryError: false,
	// 	designerError: false,
	// 	issue: "",
	// 	employee_id: 1,
	// },
];
