import { LucideProps, Pen } from "lucide-react";
import React from "react";
import { Badge } from "../../../components/ui/Badge";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../../components/ui/Select";

type iconWithColor = {
	label: string;
	value: number;
	icon?: React.ForwardRefExoticComponent<
		Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>
	>;
	color?: string;
};

const SelectChangeValue = ({
	values,
	onValueChange,
	selectedValue,
	inCell = true,
	disabled = false,
}: {
	values: iconWithColor[];
	selectedValue: iconWithColor;
	onValueChange: (value: string) => void;
	disabled?: boolean;
	inCell?: boolean;
}) => {
	return (
		<Select
			value={selectedValue.value.toString()}
			onValueChange={onValueChange}
			disabled={disabled}
		>
			<SelectTrigger
				className={` ${
					inCell && "!border-none !outline-none !ring-0 !shadow-none w-fit"
				}`}
			>
				<SelectValue placeholder={<Pen size={14} className="mr-1" />} />
			</SelectTrigger>
			<SelectContent>
				{values.map((item, j) => (
					<SelectItem key={j} value={item.value.toString()}>
						<Badge
							style={{
								...(() => {
									if (item.color) {
										return {
											backgroundColor: item.color,
										};
									}
								})(),
							}}
							className={`flex items-center gap-2 whitespace-nowrap ${
								!item.color && "!bg-background text-primary"
							}`}
						>
							<>
								{item.icon && <item.icon className="mr-2 h-4 w-4" />}
								{item.label}
							</>
						</Badge>
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};

export default SelectChangeValue;
