import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { z } from "zod";
import { Button } from "../../../components/ui/Button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "../../../components/ui/Dialog";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../../../components/ui/Form";
import { Input } from "../../../components/ui/Input";
import { dialogState } from "../../../store/dialog";
import { toast } from "sonner";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useService from "../../../hooks/use-service";
import { AxiosError } from "axios";

const formSchema = z.object({
	day: z.string().min(0),
	hour: z.string().min(0),
	minute: z.string().min(0),
});

const ConditionalForm = () => {
	const { editTask } = useService();
	const { id } = useParams();
	const [state, setState] = useRecoilState(dialogState);
	const queryClient = useQueryClient();

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			day: "0",
			hour: "0",
			minute: "0",
		},
	});

	const mutation = useMutation({
		mutationKey: ["edit-task", id],
		mutationFn: ({ task_id, form }: { task_id: number | string; form: any }) =>
			editTask(task_id, form),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["tasks", id] });
		},
	});

	const applyChange = (id: number | string, val: any) => {
		toast.promise(
			mutation.mutateAsync({
				task_id: id,
				form: val,
			}),
			{
				loading: "Updating Cell",
				error: (e: AxiosError) => {
					if (e.response?.status === 422) {
						const res = e.response.data as any;
						if (Object.hasOwn(res, "message")) {
							if (typeof res.message === "string") {
								return res.message || "";
							}
						}
					}
					console.log(e);
					return "Something Went Wrong";
				},
				success: () => {
					setState({
						id: -1,
						open: false,
					});
					form.reset();
					return "Cell updated successfully";
				},
			}
		);
	};

	function onSubmit(values: z.infer<typeof formSchema>) {
		const formData = new FormData();
		formData.append("status", "3");
		formData.append(
			"completed_hours",
			`${values.day}:${values.hour}:${values.minute}`
		);
		applyChange(state.id, formData);
	}

	return (
		<Dialog
			open={state.open}
			onOpenChange={(e) =>
				setState((prev) => ({
					...prev,
					open: e,
				}))
			}
		>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>How Much Hours To Complete</DialogTitle>
					<DialogDescription>
						<Form {...form}>
							<form
								onSubmit={form.handleSubmit(onSubmit)}
								className="space-y-8"
							>
								<FormField
									control={form.control}
									name="day"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Days</FormLabel>
											<FormControl>
												<Input
													type={"number"}
													min={0}
													placeholder="day"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="hour"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Hours</FormLabel>
											<FormControl>
												<Input
													type={"number"}
													min={0}
													max={23}
													placeholder="hour"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="minute"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Minutes</FormLabel>
											<FormControl>
												<Input
													type={"number"}
													min={0}
													max={59}
													placeholder="minutes"
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<Button type="submit">Submit</Button>
							</form>
						</Form>
					</DialogDescription>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default ConditionalForm;
