import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userData as _userData } from "../../store/user";

const IsLoggedIn = () => {
	const navigate = useNavigate();
	const [userData, setUserData] = useRecoilState(_userData);

	if (!userData || userData.token.length === 0) {
		setUserData(null);
		setTimeout(() => {
			navigate("/login");
		}, 500);
    return <></>;
	}

	return <Outlet />;
};

export default IsLoggedIn;
