"use client";

import { ScanEyeIcon } from "lucide-react";
import { Button } from "../../../components/ui/Button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../../../components/ui/Dialog";

export function ShowImages({ file }: { file: string }) {
	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button variant="outline" size={"icon"}>
					<ScanEyeIcon />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-xl">
				<DialogHeader>
					<DialogTitle>Show All Images</DialogTitle>
				</DialogHeader>
				<div className="">
					<a href={file} target="_blank" rel="noreferrer" className="">
						<img
							className="aspect-auto h-[30dvh] rounded-md border-[1px]"
							src={file}
							alt={file}
						/>
					</a>
				</div>
			</DialogContent>
		</Dialog>
	);
}
