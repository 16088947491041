import { RouterProvider } from "react-router-dom";
import { RecoilRoot } from "recoil";
import useRouter from "./hooks/use-router";

function App() {
	const router = useRouter();

	return (
		<RecoilRoot>
			<RouterProvider router={router} />
		</RecoilRoot>
	);
}

export default App;
