"use client";

import { Cross2Icon } from "@radix-ui/react-icons";
import { Table } from "@tanstack/react-table";
import { useRecoilValue } from "recoil";
import { Button } from "../../../components/ui/Button";
import { projectDetails } from "../../../store/tasks";
// import { Input } from "../../../components/ui/Input";

import { assignedTo, priorities, statuses } from "../data/data";
import CreateNewRow from "./create-new-row-modal";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import { DataTableViewOptions } from "./data-table-view-options";

interface DataTableToolbarProps<TData> {
	table: Table<TData>;
}

export function DataTableToolbar<TData>({
	table,
}: DataTableToolbarProps<TData>) {
	const isFiltered = table.getState().columnFilters.length > 0;
	const { where_tags } = useRecoilValue(projectDetails);

	return (
		<div className="flex items-center justify-between">
			<div className="flex flex-1 items-center space-x-2">
				{/* <Input
					placeholder="Filter tasks..."
					value={(table.getColumn("issue")?.getFilterValue() as string) ?? ""}
					onChange={(event) => {
						return table.getColumn("issue")?.setFilterValue(event.target.value);
					}}
					className="h-8 w-[150px] lg:w-[250px]"
				/> */}
				{table.getColumn("priority") && (
					<DataTableFacetedFilter
						column={table.getColumn("priority")}
						title="Priority"
						options={priorities}
					/>
				)}
				{table.getColumn("assigned_to") && (
					<DataTableFacetedFilter
						column={table.getColumn("assigned_to")}
						title="Assigned To"
						options={assignedTo}
					/>
				)}
				{table.getColumn("status") && (
					<DataTableFacetedFilter
						column={table.getColumn("status")}
						title="Status"
						options={statuses}
					/>
				)}
				{where_tags && where_tags.length > 0 && table.getColumn("where") && (
					<DataTableFacetedFilter
						column={table.getColumn("where")}
						title="Where"
						options={where_tags}
					/>
				)}
				{isFiltered && (
					<Button
						variant="ghost"
						onClick={() => table.resetColumnFilters()}
						className="h-8 px-2 lg:px-3"
					>
						Reset
						<Cross2Icon className="ml-2 h-4 w-4" />
					</Button>
				)}
			</div>
			<div className="flex items-center gap-3">
				<DataTableViewOptions table={table} />
				<CreateNewRow />
			</div>
		</div>
	);
}
