"use client";

import { UploadCloud } from "lucide-react";
import * as React from "react";

import { Button } from "../../../components/ui/Button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../../../components/ui/Dialog";
import { FileUploader } from "../../../components/ui/FileUploader";

export function DialogUploaderDemo({
	disabled,
	changeApplied,
}: {
	disabled: boolean;
	changeApplied: (e: File) => void;
}) {
	const [files, setFiles] = React.useState<File[]>([]);

	const handleUpload = () => {
		changeApplied(files[0]);
	};

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button variant="outline" size={"icon"} disabled={disabled}>
					<UploadCloud />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-xl">
				<DialogHeader>
					<DialogTitle>Upload files</DialogTitle>
					<DialogDescription>
						Drag and drop your files here or click to browse.
					</DialogDescription>
				</DialogHeader>
				<FileUploader
					maxFileCount={1}
					maxSize={1 * 1024 * 1024}
					onValueChange={setFiles}
				/>
				<Button
					variant="default"
					className="w-fit justify-self-end"
					onClick={handleUpload}
				>
					Upload Selected
				</Button>
			</DialogContent>
		</Dialog>
	);
}
