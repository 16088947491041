import { atom } from "recoil";

export type TDialogState = {
  open: boolean,
  id: number
}

export const dialogState = atom<TDialogState>({
  key: "dialogState",
  default: {
    open: false,
    id: -1
  }
})