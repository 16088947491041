import { Loader, Plus } from "lucide-react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "../../../components/ui/Button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../../../components/ui/Dialog";
import { projectDetails } from "../../../store/tasks";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../../../components/ui/Form";
import { Input } from "../../../components/ui/Input";
import SelectChangeValue from "./select-change-value";
import { assignedTo, priorities, statuses } from "../data/data";
import { ScrollArea } from "../../../components/ui/ScrollArea";
import { Textarea } from "../../../components/ui/Textarea";
import useHasPermission from "../../../hooks/use-has-permission";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "../../../components/ui/Select";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import useService from "../../../hooks/use-service";
import { FileUploader } from "../../../components/ui/FileUploader";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { AxiosError } from "axios";

const formSchema = z.object({
	priority: z.number(),
	assigned_to: z.number(),
	status: z.number(),
	where: z.number(),
	section: z.string().min(5),
	issue: z.string().min(5),
	employee_id: z.number().nullable(),
	screenshot: z.any(),
});

const CreateNewRow = () => {
	const { id } = useParams();

	const { hasPermission } = useHasPermission();
	const adminPrivilege = hasPermission("*");
	const queryClient = useQueryClient();
	const { createTask } = useService();

	const { employees, where_tags } = useRecoilValue(projectDetails);
	const [selected, setSelected] = useState(0);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			assigned_to: assignedTo[0].value,
			priority: priorities[0].value,
			section: "",
			status: statuses[0].value,
			where: where_tags && where_tags.length > 0 ? where_tags[0].value : 1,
			issue: "",
			employee_id:
				(employees && employees.length > 0 && employees[0].id) || null,
		},
	});

	useEffect(() => {
		setSelected(assignedTo[0].value);
	}, []);

	const mutation = useMutation({
		mutationFn: (form: any) => createTask(form),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["tasks", id] });
		},
	});

	function onSubmit(values: z.infer<typeof formSchema>) {
		const formData = new FormData();

		console.log(values);

		Object.keys(values).forEach((k) => {
			const val = values[k as keyof typeof values];
			if (val || val === 0) {
				formData.append(k, val as string);
			}
		});

		formData.append("project_id", id?.toString() || "");
		formData.append(
			"reported_at",
			`${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}`
		);

		mutation
			.mutateAsync(formData)
			.then(() => {
				form.reset();
			})
			.catch((e: AxiosError) => {
				console.log("error", e);
				if (e.response?.status === 422) {
					const res = e.response.data as any;
					if (Object.hasOwn(res, "message")) {
						if (typeof res.message === "string") {
							toast.error(res.message || "");
						} else {
							toast.error("Something Went Wrong");
						}
					} else {
						toast.error("Something Went Wrong");
					}
				} else {
					toast.error("Something Went Wrong");
				}
			})
			.finally(() => {});
	}

	return (
		<Dialog>
			<DialogTrigger disabled={!adminPrivilege}>
				<Button
					variant="outline"
					size="icon"
					className="ml-auto hidden h-8 lg:flex"
					asChild
				>
					<Plus />
				</Button>
			</DialogTrigger>
			<DialogContent className="!p-0">
				<ScrollArea className="max-h-[70vh]">
					<DialogHeader className="p-5">
						<DialogTitle>Create New Task</DialogTitle>
						<DialogDescription>
							<Form {...form}>
								<form
									onSubmit={form.handleSubmit(onSubmit)}
									className="space-y-8"
								>
									<FormField
										control={form.control}
										name="where"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Where</FormLabel>
												<SelectChangeValue
													inCell={false}
													selectedValue={
														where_tags && where_tags.length > 0
															? where_tags.find(
																	(w) => w.value === field.value
															  ) || {
																	label: "No Data",
																	value: -10,
																	color: "red",
															  }
															: {
																	label: "No Data",
																	value: -10,
																	color: "red",
															  }
													}
													values={where_tags || []}
													onValueChange={(e) =>
														field.onChange(Number.parseInt(e))
													}
												/>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="section"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Section</FormLabel>
												<FormControl>
													<Input
														placeholder="header, footer, form..."
														{...field}
													/>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="priority"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Priority</FormLabel>
												<SelectChangeValue
													inCell={false}
													selectedValue={
														priorities.find(
															(priority) => priority.value === field.value
														) || priorities[0]
													}
													values={priorities}
													onValueChange={(e) =>
														field.onChange(Number.parseInt(e))
													}
												/>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="assigned_to"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Assign To</FormLabel>
												<SelectChangeValue
													inCell={false}
													selectedValue={
														assignedTo.find(
															(assigned) => assigned.value === field.value
														) || assignedTo[0]
													}
													values={assignedTo}
													onValueChange={(e) => {
														setSelected(Number.parseInt(e));
														field.onChange(Number.parseInt(e));
													}}
												/>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="employee_id"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Employee</FormLabel>
												<Select
													value={(field.value || "").toString()}
													onValueChange={(e) => {
														field.onChange(Number.parseInt(e));
													}}
													disabled={!adminPrivilege}
												>
													<SelectTrigger>
														<SelectValue placeholder="Select an Employees" />
													</SelectTrigger>
													<SelectContent>
														<SelectGroup>
															<SelectLabel>Employees</SelectLabel>
															{/* eslint-disable-next-line array-callback-return */}
															{employees &&
																employees?.length > 0 &&
																employees?.map(
																	(emp) =>
																		emp.type !== 1 &&
																		((emp.type === 2 && selected === 1) ||
																			(emp.type === 3 && selected === 2) ||
																			selected === 3 ||
																			selected === 4) && (
																			<SelectItem
																				value={emp.id.toString()}
																				id={emp.id.toString()}
																			>
																				{emp.name}
																			</SelectItem>
																		)
																)}
														</SelectGroup>
													</SelectContent>
												</Select>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="status"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Status</FormLabel>
												<SelectChangeValue
													inCell={false}
													selectedValue={
														statuses.find(
															(status) => status.value === field.value
														) || statuses[0]
													}
													values={statuses}
													onValueChange={(e) =>
														field.onChange(Number.parseInt(e))
													}
												/>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="screenshot"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Screenshot</FormLabel>
												<FileUploader
													maxFileCount={1}
													maxSize={1 * 1024 * 1024}
													onValueChange={(file) => {
														field.onChange(file[0]);
													}}
												/>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="issue"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Issue</FormLabel>
												<Textarea {...field} />
												<FormMessage />
											</FormItem>
										)}
									/>
									<div className="flex items-center justify-end">
										<Button
											type="submit"
											className="flex items-center gap-2"
											disabled={mutation.isPending}
										>
											Submit{" "}
											{mutation.isPending && (
												<Loader
													size={16}
													className="animate-spin duration-700"
												/>
											)}
										</Button>
									</div>
								</form>
							</Form>
						</DialogDescription>
					</DialogHeader>
				</ScrollArea>
			</DialogContent>
		</Dialog>
	);
};

export default CreateNewRow;
