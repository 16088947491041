import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { z } from "zod";
import { Button } from "../../components/ui/Button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "../../components/ui/Card";
import {
	Form,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../../components/ui/Form";
import { Input } from "../../components/ui/Input";
import useService from "../../hooks/use-service";
import { userData } from "../../store/user";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

const formSchema = z.object({
	email: z.string(),
	password: z.string(),
});

export default function Login() {
	const { login } = useService();
	const [, setUserData] = useRecoilState(userData);
	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setUserData(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: "",
			password: "",
		},
	});

	function onSubmit(values: z.infer<typeof formSchema>) {
		setLoading(true);
		login(values)
			.then((res) => {
				setUserData(res.data);
				form.reset();
				toast.success("Success", { description: "Navigating to home page" });
				setTimeout(() => {
					navigate("/");
				}, 500);
			})
			.catch((err) => {
				toast.error("Something went wrong");
			})
			.finally(() => {
				setLoading(false);
			});
	}

	return (
		<div className="min-h-screen flex items-center justify-center">
			<Card className="w-full max-w-md">
				<CardHeader>
					<CardTitle className="text-2xl font-bold text-center">
						Login
					</CardTitle>
					<CardDescription className="text-center">
						Enter your credentials to access app
					</CardDescription>
				</CardHeader>
				<CardContent>
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
							<FormField
								control={form.control}
								name="email"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Email</FormLabel>
										<Input placeholder="email" type={"email"} {...field} />
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="password"
								render={({ field }) => (
									<FormItem>
										<FormLabel>Password</FormLabel>
										<Input
											placeholder="********"
											type={"password"}
											{...field}
										/>
										<FormMessage />
									</FormItem>
								)}
							/>
							<div className="flex items-center justify-end">
								<Button
									disabled={loading}
									type="submit"
									className="flex items-center gap-2 disabled:cursor-not-allowed"
								>
									{loading && (
										<Loader2 className="animate-spin duration-700" size={16} />
									)}
									Submit
								</Button>
							</div>
						</form>
					</Form>
				</CardContent>
			</Card>
			<div className="absolute bottom-5 right-5">
				<Link
					to={"https://lucidly.ae"}
					className="flex items-center gap-2"
					target={"_blank"}
				>
					<img src="/image.png" alt="Lucidly Logo" className="size-8" />
				</Link>
			</div>
		</div>
	);
}
