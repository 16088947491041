import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import { CalendarIcon, UsersIcon, CheckSquareIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../ui/Button";

interface ProjectCardProps {
	name: string;
	started_at: string;
	employees_count: number;
	tasks_count: number;
	tasks_completed: number;
	id: number;
}

function PieChart({ completed, total }: { completed: number; total: number }) {
	const completedAngle = (completed / total) * 360;
	const notCompletedAngle = 360 - completedAngle;

	return (
		<svg width="100" height="100" viewBox="0 0 100 100">
			<circle cx="50" cy="50" r="45" fill="#e2e8f0" />
			{completedAngle > 0 && (
				<path
					d={`M50 50 L50 5 A45 45 0 ${completedAngle > 180 ? 1 : 0} 1 ${
						50 + 45 * Math.sin(completedAngle * (Math.PI / 180))
					} ${50 - 45 * Math.cos(completedAngle * (Math.PI / 180))} Z`}
					fill="#4ade80"
				/>
			)}
			{notCompletedAngle > 0 && (
				<path
					d={`M50 50 L${50 + 45 * Math.sin(completedAngle * (Math.PI / 180))} ${
						50 - 45 * Math.cos(completedAngle * (Math.PI / 180))
					} A45 45 0 ${notCompletedAngle > 180 ? 1 : 0} 1 50 5 Z`}
					fill="#f87171"
				/>
			)}
		</svg>
	);
}

export default function ProjectCard({
	employees_count,
	id,
	name,
	started_at,
	tasks_completed,
	tasks_count,
}: ProjectCardProps) {
	return (
		<Card className="w-full">
			<CardHeader>
				<CardTitle className="text-2xl font-bold">Project {name}</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="bg-secondary p-2 rounded mb-4">
					<div className="flex justify-center mb-4">
						<PieChart completed={tasks_completed} total={tasks_count} />
					</div>
					<div className="flex justify-center space-x-4">
						<div className="flex items-center">
							<div className="w-3 h-3 bg-[#4ade80] rounded-full mr-2"></div>
							<span className="text-sm">Completed</span>
						</div>
						<div className="flex items-center">
							<div className="w-3 h-3 bg-[#f87171] rounded-full mr-2"></div>
							<span className="text-sm">Not Completed</span>
						</div>
					</div>
				</div>
				<div className="space-y-4">
					<div className="flex items-center space-x-2">
						<CalendarIcon className="h-5 w-5 text-primary" />
						<span className="text-sm">Created on {started_at}</span>
					</div>
					<div className="flex items-center space-x-2">
						<UsersIcon className="h-5 w-5 text-primary" />
						<span className="text-sm">{employees_count} contributors</span>
					</div>
					<div className="flex items-center space-x-2">
						<CheckSquareIcon className="h-5 w-5 text-primary" />
						<span className="text-sm">
							{tasks_completed} / {tasks_count} tasks completed
						</span>
					</div>
				</div>
				<div className="flex justify-end mt-2">
					<Button asChild>
						<Link to={`${id}`}>Navigate to project</Link>
					</Button>
				</div>
			</CardContent>
		</Card>
	);
}
