import { createBrowserRouter } from "react-router-dom";
import IsLoggedIn from "../components/utils/IsLoggedIn";
import Login from "../pages/auth/Login";
import Projects from "../pages/projects/Projects";
import TaskPage from "../pages/tasks/page";

const useRouter = () => {
	const router = createBrowserRouter([
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/",
			element: <IsLoggedIn />,
			children: [
				{
					path: "",
					element: <Projects />,
				},
				{
					path: ":id",
					element: <TaskPage />,
				},
			],
		},
	]);

	return router;
};

export default useRouter;
