import {
	ArrowDownIcon,
	ArrowRightIcon,
	ArrowUpIcon,
	CheckCheckIcon,
	RefreshCw,
	CrossIcon,
	LucideMessageCircleQuestion,
	WatchIcon,
} from "lucide-react";

export const statuses = [
	{
		value: 1,
		label: "Not Taken",
		icon: LucideMessageCircleQuestion,
		color: "#6a95a1",
	},
	{
		value: 2,
		label: "In Progress",
		icon: WatchIcon,
		color: "orange",
	},
	{
		value: 3,
		label: "Completed",
		icon: CheckCheckIcon,
		color: "green",
	},
	{
		value: 4,
		label: "Impossible To Do",
		icon: CrossIcon,
		color: "red",
	},
	{
		value: 5,
		label: "Back In Rework",
		icon: RefreshCw,
		color: "blue",
	},
];

export const priorities = [
	{
		label: "Low",
		value: 0,
		icon: ArrowDownIcon,
		color: "green",
	},
	{
		label: "Medium",
		value: 1,
		icon: ArrowRightIcon,
		color: "orange",
	},
	{
		label: "High",
		value: 2,
		icon: ArrowUpIcon,
		color: "red",
	},
];

export const assignedTo = [
	{
		value: 1,
		label: "Development",
		color: "blue",
	},
	{
		value: 2,
		label: "Data Entry",
		color: "purple",
	},
	{
		value: 3,
		label: "Unsure",
		color: "violet",
	},
	{
		value: 4,
		label: "Testing",
		color: "red",
	},
];

export const where = [
	{
		value: 1,
		label: "General",
		color: "brown",
	},
	{
		value: 2,
		label: "Desktop",
		color: "violet",
	},
	{
		value: 3,
		label: "Mobile",
		color: "purple",
	},
];
