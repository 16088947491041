import { atom } from "recoil";
import { tasks } from "../pages/tasks/data/tasks";

export const taskAtom = atom<
	{
		id: number;
		priority: number;
		assigned_to: number;
		status: number;
		where: number;
		section: string;
		screenshot: string;
		reported_at: Date | null;
		resolved_at: Date | null;
		developer_notes: string;
		design_error: boolean;
		data_entry_error: boolean;
		verified_by_pm: boolean;
		issue: string;
		employee_id: number;
		completed_hours: string | null;
		start_handle_at: string | null;
	}[]
>({
	key: "taskStoreAtom",
	default: tasks,
});

export const projectDetails = atom<{
	project: {
		employees_count: number;
		name: string;
		tasks_count: number;
	} | null;
	employees:
		| {
				id: number;
				name: string;
				email: string;
				type: number;
				created_at: string;
				updated_at: string;
		  }[]
		| null;
	where_tags:
		| {
				value: number;
				label: string;
				color: string;
		  }[]
		| null;
}>({
	key: "projectDetails",
	default: {
		project: null,
		employees: null,
		where_tags: null,
	},
});
