import { Loader2, LogOut } from "lucide-react";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Button } from "../../components/ui/Button";
import ProjectCard from "../../components/utils/ProjectsCard";
import { ModeToggle } from "../../components/utils/ThemeToggle";
import useService, { TGetProjects } from "../../hooks/use-service";
import { userData } from "../../store/user";

const Projects = () => {
	const { getProjects } = useService();
	const [data, setData] = useState<TGetProjects>([]);
	const [loading, setLoading] = useState(false);
	const [, setUserData] = useRecoilState(userData);

	useEffect(() => {
		setLoading(true);
		getProjects()
			.then((res) => {
				setData(res.data);
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleLogout = () => {
		localStorage.clear();
		setUserData(null);
		setTimeout(() => {
			window.location.pathname = "/";
		}, 200);
	};

	return (
		<div className="container mx-auto p-4 ">
			<div className="mb-5 flex items-center justify-between">
				<h1 className="text-3xl font-bold ">List Of Projects</h1>

				<div className="flex items-center space-x-2">
					<ModeToggle />
					<Button variant={"outline"} size="icon" onClick={handleLogout}>
						<LogOut />
					</Button>
				</div>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-4 gap-5">
				{loading ? (
					<div className="col-span-3 flex items-center justify-center mt-10">
						<Loader2 className="animate animate-spin duration-700" />
					</div>
				) : (
					data &&
					data.length > 0 &&
					data.map((item) => <ProjectCard {...item} key={item.id} />)
				)}
			</div>
		</div>
	);
};

export default Projects;
