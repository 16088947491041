import { useQuery } from "@tanstack/react-query";
import { ChevronLeftIcon, LogOut } from "lucide-react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Avatar, AvatarFallback } from "../../components/ui/Avatar";
import { Button } from "../../components/ui/Button";
import Skeleton from "../../components/ui/Skeleton";
import { ModeToggle } from "../../components/utils/ThemeToggle";
import useService, { TGetProjectDetails } from "../../hooks/use-service";
import { projectDetails, taskAtom } from "../../store/tasks";
import { userData } from "../../store/user";
import { useColumns } from "./components/columns";
import ConditionalForm from "./components/conditional-form";
import { DataTable } from "./components/data-table";

export default function TaskPage() {
	const { id } = useParams();
	const navigate = useNavigate();
	const columns = useColumns();

	const [tasks, setTasks] = useRecoilState(taskAtom);
	const [details, setDetails] = useRecoilState(projectDetails);
	const { getProjectDetails } = useService();
	const [d, setUserData] = useRecoilState(userData);

	if (!id) {
		navigate("/");
	}

	const { data, isLoading, isSuccess, isPending } = useQuery({
		queryKey: ["tasks", id],
		queryFn: () => getProjectDetails(id || ""),
	});

	useEffect(() => {
		if (isSuccess) {
			setTasks((data.data as TGetProjectDetails).tasks);
			setDetails({
				employees: (data.data as TGetProjectDetails).employees,
				project: (data.data as TGetProjectDetails).project,
				where_tags: (data.data as TGetProjectDetails).where_tags,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isSuccess, isPending]);

	const handleLogout = () => {
		localStorage.clear();
		setUserData(null);
		setTimeout(() => {
			window.location.pathname = "/";
		}, 200);
	};

	return (
		<>
			<div className="hidden h-full flex-1 flex-col space-y-4 p-4 md:flex">
				<div className="flex items-center justify-between space-y-2">
					<div className="flex items-center justify-between">
						<div className="flex items-center gap-2">
							<Link to={"/"}>
								<ChevronLeftIcon />
							</Link>
							{isLoading ? (
								<Skeleton className="h-8 w-80" />
							) : (
								<h2 className="text-2xl font-bold tracking-tight">
									{details.project?.name}
								</h2>
							)}
						</div>
					</div>
					<div className="flex items-center space-x-2">
						{isLoading ? (
							<Skeleton className="h-10 w-10 !rounded-full" />
						) : (
							<Avatar>
								<AvatarFallback>{d?.name[0]}</AvatarFallback>
							</Avatar>
						)}
						<ModeToggle />
						<Button variant={"outline"} size="icon" onClick={handleLogout}>
							<LogOut />
						</Button>
					</div>
				</div>
				<div className="flex items-center gap-2">
					<div className="flex items-center gap-1">
						<span className="font-bold">Managers:</span>{" "}
						{isLoading ? (
							<Skeleton className="h-6 w-32" />
						) : (
							details.employees &&
							details.employees.length &&
							details.employees
								.filter((item) => item.type === 1 && item.name)
								.map((em) => em.name)
								.join(", ")
						)}
					</div>{" "}
					|
					<div className="flex items-center gap-1">
						<span className="font-bold">Developers:</span>{" "}
						{isLoading ? (
							<Skeleton className="h-6 w-32" />
						) : (
							details.employees &&
							details.employees.length &&
							details.employees
								.filter((item) => item.type === 2 && item.name)
								.map((em) => em.name)
								.join(", ")
						)}
					</div>{" "}
					|
					<div className="flex items-center gap-1">
						<span className="font-bold">Data Entries:</span>{" "}
						{isLoading ? (
							<Skeleton className="h-6 w-32" />
						) : (
							details.employees &&
							details.employees.length &&
							details.employees
								.filter((item) => item.type === 3 && item.name)
								.map((em) => em.name)
								.join(", ")
						)}
					</div>
				</div>
				<div className="flex items-center gap-2">
					<div className="flex items-center gap-1">
						<span className="font-bold">Task Count:</span>{" "}
						{isLoading ? (
							<Skeleton className="h-6 w-10" />
						) : (
							details.project?.tasks_count
						)}
					</div>{" "}
				</div>
			</div>
			<ConditionalForm />
			<DataTable data={tasks} columns={columns} />
		</>
	);
}
