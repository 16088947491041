import { useRecoilValue } from "recoil";
import { TUserPrivileges, userData } from "../store/user";

const useHasPermission = () => {
	const user = useRecoilValue(userData);

	const hasPermission = (permission: TUserPrivileges) => {
		if (!user) {
			return false;
		}

		if (
			user.privileges_task_fields.includes(permission) ||
			user.privileges_task_fields[0] === "*"
		) {
			return true;
		}

		return false;
	};

	return { hasPermission };
};

export default useHasPermission;
