import { useAxiosInstance } from "../lib/axios";
import { ENDPOINTS } from "../utils/END_POINTS";

export type TGetProjects = {
	name: string;
	started_at: string;
	employees_count: number;
	tasks_count: number;
	tasks_completed: number;
	id: number;
}[];

export type TGetProjectDetails = {
	employees: {
		id: number;
		name: string;
		email: string;
		type: number;
		created_at: string;
		updated_at: string;
	}[];
	project: {
		employees_count: number;
		name: string;
		tasks_count: number;
	};
	tasks: {
		id: number;
		priority: number;
		assigned_to: number;
		status: number;
		where: number;
		section: string;
		screenshot: string;
		reported_at: Date | null;
		resolved_at: Date | null;
		developer_notes: string;
		design_error: boolean;
		data_entry_error: boolean;
		verified_by_pm: boolean;
		issue: string;
		employee_id: number;
		completed_hours: string | null;
		start_handle_at: string | null;
	}[];
	where_tags: {
		value: number;
		label: string;
		color: string;
	}[];
};

const useService = () => {
	const { axiosClient } = useAxiosInstance();

	return {
		login: (body: { email: string; password: string }) =>
			axiosClient.post(ENDPOINTS.login, body),

		getProjects: () => axiosClient.get(ENDPOINTS.list_projects),

		getProjectDetails: (id: string) => axiosClient.get(ENDPOINTS.details(id)),

		createTask: (data: any) => axiosClient.post(ENDPOINTS.create_task, data),

		editTask: (task_id: string | number, data: any) =>
			axiosClient.post(ENDPOINTS.edit_task(task_id), data),
	};
};

export default useService;
